import { render, staticRenderFns } from "./Personneletting.vue?vue&type=template&id=ff633340&scoped=true&"
import script from "./Personneletting.vue?vue&type=script&lang=js&"
export * from "./Personneletting.vue?vue&type=script&lang=js&"
import style0 from "./Personneletting.vue?vue&type=style&index=0&id=ff633340&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff633340",
  null
  
)

export default component.exports