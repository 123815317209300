<template>
	<div class="FieldSettings">
		<a-tabs v-model:activeKey="activeKey" @change='changeTopTab'>
			<a-tab-pane key="1" tab="员工属性 ">
				<div class="tips_top_box" v-show="visit2">
					<p class="tips_titele_box">
						<span class="tips_title">使用指南</span>
						<span class="close_open" @click='visit2=false'>关闭提示</span>
					</p>
					<p> 为了便于人事档案管理，员工属性字段分为“在职信息”、“个人信息”、“联系信息”、“工资社保”。</p>
					<p> 系统默认的分类和属性字段无法被删除或者修改，如果您的企业需要增加字段，可以在对应的分类下添加。</p>
				</div>
				<div class="tab_info">
					<div style="flex: 1;">
						<a-tabs type="card" v-model:activeKey="activeKeyTab" @change='get_Employee_Attributes_List'>
							<a-tab-pane :key="item.value" :tab="item.label" v-for="(item,index) in tablist">
							</a-tab-pane>
						</a-tabs>
					</div>
					<div style="margin-bottom: 5px;border-bottom: 1px solid #eee;padding-bottom: 5px;">
						<!-- <PlusCircleOutlined class='cq_color' /> -->

						<span v-auth="'change'" style="margin-left: 6px;" class="cq_color"
							@click="open_add">添加字段属性</span>
					</div>
				</div>
				<div v-for="item in Attributes_List">
					<div class="top_title_info">
						<span class="cq_line_span"></span>
						<span>{{item.name}}<span class="cq_tips_font" v-if="item.describe">
								({{item.describe}})</span></span>
						<a-popover title="示例图">
							<template #content>
								<img :src="item.image" class="sLimg">
							</template>
							<span v-show="item.image" class="cq_color">示例图</span>
						</a-popover>
						<a-divider style="margin:0 12px;" type="vertical" v-if="item.can_add==1" />
						<span v-auth="'change'" v-if="item.can_add==1" class="cq_color"
							@click="addVisible=true;form.pid=item.id">添加</span>
						<a-divider style="margin:0 12px;" type="vertical" v-if="item.can_add==1" />
						<span v-auth="'able'" v-if="item.can_forbid==1"
							:class="item.can_forbid==1?'cq_error':'cq_color'"
							@click="changeTitleStatus(item)">{{item.status==1?'禁用':'启用'}}</span>
					</div>

					<div class="FieldSettingsBox" v-if="item.status==1">
						<div class="FieldSettingsBoxTitle FieldSettingsBoxHeader">
							<span>
								&nbsp; &nbsp;
							</span> <span>字段名称</span>
							<span style="color: #666;">描述</span>
							<span style="color: #666;">&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
								&nbsp;</span>
						</div>
						<div class="FieldSettingsBoxTitle" v-for="element in item.children" :key="element.id">
							<span>
								&nbsp; &nbsp;
							</span>
							<span>
								{{element.name}}
								<a-tag v-if="element.is_custom==1">自定义</a-tag>
								<a-tag v-if="element.is_search==1">可启用搜索</a-tag>
							</span>
							<span>
								{{element.describe?element.describe:'--'}}
							</span>
							<span class="cq_text_require">
								<span v-auth="'change'" v-if="element.can_edit==1" class="cq_color"
									@click="get_EmployeeAttributeDetail(element)">编辑 </span>
								<span v-auth="'delete'" v-if="element.can_delete==1" class="cq_error"
									@click="changStatus(element)">删除
								</span>
							</span>
						</div>

					</div>

					<div v-else class="stopBox">
						<p class="top_info_tips">
							<a-icon type="stop" /> <span>未启用</span>
						</p>
						<p>
							如果员工人事资料需要保存此项信息，请先启用
						</p>
					</div>


				</div>


			</a-tab-pane>
			<a-tab-pane key="2" tab="员工档案材料 ">
				<div class="tips_top_box" style="display: flex;">
					<div id="" style="flex: 1;" class="search_form">
						<span class="cq_text_require">*</span><span id="">
							在选择组织人员的时候，可按以下的限定条件筛选出一批特定的员工
						</span>

					</div>
					<a-button v-auth="'change'" class="reset pointer pointerCancel" @click='set_ProfileInfo'
						type="primary">添加类型
					</a-button>
				</div>
				<div v-for="item in Attributes_List">
					<div class="top_title_info">
						<span class="cq_line_span"></span>
						<span>{{item.name}}<span class="cq_tips_font" v-if="item.describe">
								({{item.describe}})</span></span>
						<span v-auth="'sort'" class="cq_color'" @click="get_EmployeeProfileCateInfo(item.pid)">排序</span>
					</div>
					<div class="FieldSettingsBox">
						<div class="FieldSettingsBoxTitle FieldSettingsBoxHeader">
							<span>
								&nbsp; &nbsp;
							</span> <span>字段名称</span> <span style="color: #666;">描述</span> <span
								style="color: #666;width: 120px;">&nbsp; &nbsp;</span>
						</div>
						<div class="FieldSettingsBoxTitle" v-for="element in item.children">
							<span>
								&nbsp; &nbsp;
							</span>
							<span>
								{{element.name}}
								<a-tag v-if="element.is_custom==1">自定义</a-tag>
								<a-tag v-if="element.is_search==1">可启用搜索</a-tag>
							</span>
							<span>
								{{element.describe?element.describe:'--'}}
							</span>
							<span class="cq_text_require" style="width: 120px;">
								<span v-auth="'change'" class="cq_color" v-if="element.can_edit==1"
									@click="getProfileInfo(element)">编辑
								</span>
								<span v-auth="'delete'" v-if="element.can_delete==1" class="cq_error"
									@click="delProfileInfo(element)">删除 </span>
							</span>
						</div>
					</div>
				</div>
			</a-tab-pane>
			<a-tab-pane key="3" tab="自定义设置 ">
				<div id="">
					<CustomSetting></CustomSetting>
				</div>

			</a-tab-pane>
		</a-tabs>
		<a-modal v-model:visible="addVisibleNone" title="新增" @ok="addNoneTextOk">
			<a-form :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperColEdit">
				<a-form-item :label="item.name" v-for="item in none_text_list">
					<a-row :gutter="16">
						<a-col v-for="(it,index) in item.children" class="gutter-row" :span="6">
							<div @click='add_none_text(it)'
								:class="choodeNodeList.indexOf(Number(it.id) )>-1?'gutter-box activeBox':'gutter-box'">
								{{it.name}}
							</div>
						</a-col>
					</a-row>
				</a-form-item>
				<a-button class="cq_color pointerCancel"
					@click="addVisibleNone=false;addVisible=true;addType=='add'">+新增字段</a-button>
			</a-form>
		</a-modal>
		<a-modal v-model:visible="addVisible" :title="addType=='add'?'新增':'编辑'" @ok="addOk">
			<a-form :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperColEdit">
				<a-form-item v-if="addType=='add'" label="所属分类">
					<a-select v-model:value="form.pid" placeholder="请选择所属分类">
						<a-select-option v-for='item in CanAddField_Type' :value="item.value">
							{{item.label}}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item v-if="addType=='add'" label="字段类型">
					<a-row :gutter="16">
						<a-col v-for="(item,index) in FieldName_Type" class="gutter-row" :span="6">
							<div @click='set_fieldType(item)'
								:class="form.type==item.value?'gutter-box activeBox':'gutter-box'">{{item.label}}</div>
						</a-col>
					</a-row>
				</a-form-item>
				<a-form-item label="字段名称">
					<a-input placeholder="请输入字段名称" max='15' :disabled='form.is_custom==-1' v-model:value="form.name" />
				</a-form-item>
				<a-form-item label="字段描述">
					<a-input placeholder="请输入字段描述" v-model:value="form.describe" />
				</a-form-item>
				<a-form-item label="启用搜索">
					<a-switch v-model:checked="form.is_search" />
				</a-form-item>
				<a-form-item label="列表选项" v-if="form.type=='custom'">
					<a-input v-model:value="it.value" v-for="(it,index) in custom_option" />
					<a-button class="cq_color pointerCancel" @click="custom_option.push({value:''})"
						block>+添加更多选项</a-button>
				</a-form-item>
			</a-form>
		</a-modal>

		<a-modal v-model:visible="set_ProfileInfo_visit" @cancel="cancelProfile" :title="ProfileInfo=='add'?'新增':'编辑'"
			@ok="addProfileInfo">
			<a-form :model="ProfileInfoForm" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperColEdit">
				<a-form-item v-if="ProfileInfo=='add'" label="所属分组">
					<a-select v-model:value="ProfileInfoForm.pid" placeholder="请选择所属分组">
						<a-select-option v-for='item in ProfileInfo_Type' :value="item.value">
							{{item.label}}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="类型名称">
					<a-input placeholder="请输入类型名称" :disabled='ProfileInfoForm.is_custom==-1'
						v-model:value="ProfileInfoForm.name" />
				</a-form-item>
				<a-form-item label="描述">
					<a-input placeholder="请输入描述" v-model:value="ProfileInfoForm.describe" />
				</a-form-item>
			</a-form>
		</a-modal>
		<a-modal v-model:visible="set_sort_visit" title="排序" @ok="sortProfileInfo">
			<div class="FieldSettingsBox">
				<div :draggable="true" @dragstart="dragstart($event, -1, index)" @dragover="dragover"
					@dragenter="dragenter($event, -1, index)" @dragend="dragend($event, -1, index)" @drop="drop"
					class="FieldSettingsBoxTitle" v-for="(element,index) in sort_list">
					<span>
						&nbsp; &nbsp;
					</span>
					<span>
						{{element.name}}
					</span>
					<span style="text-align: right;">
						<a-icon type="menu" />
					</span>

				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import {
		getEmployeeAttributesList,
		getEmployeeAttributesTitle,
		getFieldNameType,
		getCanAddFieldType,
		addEmployeeAttribute,
		getEmployeeAttributeDetail,
		editEmployeeAttribute,
		changeEmployeeAttributeStatus,
		changeEmployeeAttributeField,
		getEmployeeProfileInfo,
		editEmployeeProfile,
		saveEmployeeProfile,
		getEmployeeProfileSelect,
		delEmployeeProfile,
		getEmployeeProfileCateInfo,
		sortEmployeeProfile,
		getNoneEmployeeAttributeField,
		changeMulEmployeeAttributeField
	} from '@/api/enterprise.js'
	import options from '@/assets/json/city.js'

	import CustomSetting from './CustomSetting.vue'
	// import {
	// 	VueDraggableNext
	// } from 'vue-draggable-next'

	import {
		message,
		TreeDataItem,
		TreeDragEvent,
		DropEvent,
		Modal
	} from 'ant-design-vue';

	export default {
		components: {
			CustomSetting
		},
		data() {
			return {
				stop_index: 0,
				stop_approval_index: 0,
				choodeNodeList: [],
				none_text_list: [],
				addVisibleNone: false,
				set_sort_visit: false,
				sort_list: [],
				ProfileInfo: 'add',
				addType: 'add',
				form: {
					pid:''
				},
				rules: {
					name: [{
						required: true,
						message: '请填写字段名称',
						trigger: 'blur'
					}, ],
					custom_option: [{
						required: true
					}],
				},
				addVisible: false,
				visit2: true,
				tablist: [],
				FieldName_Type: [],
				CanAddField_Type: [],
				Attributes_List: [],
				activeKey: '1',
				activeKeyTab: '2',
				chooseList: [],
				select_list: [],
				select_list2: [],
				select_list2: [],
				formState: {},
				select_list2: [],
				ViewRangeType: '1',
				custom_option: [{
					value: ''
				}],
				ViewBookEmployeestype: '1',
				ViewRangeTypeVisit: false,
				set_ProfileInfo_visit: false,
				ViewBookEmployeesVist: false,
				departmentDataFilter: [],
				labelColEdit: {
					span: 2
				},
				labelCol: {
					span: 4
				},
				wrapperColEdit: {
					span: 20
				},
				wrapperCol: {
					span: 12
				},

				scaleType: [],
				select_list: [],
				write_off_reason: [],
				chooseList: [],
				mailInfo_list: [
					[],
					[],
					[]
				],
				ProfileInfo_Type: [],
				ProfileInfoForm: {}

			}

		},
		created() {
			if (this.$route.query.type) {
				this.activeKey = this.$route.query.type
			} else {
				if (sessionStorage.getItem("Personneletting_status")) {
					this.activeKey = sessionStorage.getItem("Personneletting_status");
				}
			}
			if (this.activeKey == 1) {
				this.get_Employee_Attributes_Title()
			}
			if (this.activeKey == 2) {
				this.get_Employee_ProfileInfo()
				this.get_EmployeeProfileSelect()
			}
			this.get_FieldNameType()
			this.get_type()

		},
		methods: {
			get_type() {
				getCanAddFieldType().then(res => {
					this.CanAddField_Type = res.list
			
				})
			},
			dragstart(e, ind) {
				// e.preventDefault()
			},
			drop(e) {
				e.preventDefault()
			},
			// 拖拽位置
			dragenter(e, ind, index) {
				e.preventDefault()
				if (ind != -1) {
					this.stop_index = ind;
				}
				this.stop_approval_index = index
			},
			dragover(e) {
				e.preventDefault()
			},
			// 拖拽完成
			dragend(e, ind, index) {
				let list = this.sort_list;
				let move_data = list[index];
				list.splice(index, 1)
				list.splice(this.stop_approval_index, 0, move_data)
				this.sort_list = list;


			},

			set_fieldType(item) {
				this.form.type = item.value
				this.$forceUpdate()
			},
			addNoneTextOk() {
				changeMulEmployeeAttributeField({
					data: {
						field_ids: this.choodeNodeList.join(',')
					}
				}).then(res => {
					this.get_Employee_Attributes_List()
					this.addVisibleNone = false
					this.choodeNodeList = []
					this.addType == 'add'
					message.success('操作成功');
					// this.get_none_text()
				})
			},
			add_none_text(item) {
				let ind = this.choodeNodeList.indexOf(Number(item.id))
				if (ind > -1) {
					this.choodeNodeList.splice(ind, 1)
				} else {
					this.choodeNodeList.push(Number(item.id))
				}
			},
			open_add() {
				this.get_none_text()
			},
			get_none_text() {
				getNoneEmployeeAttributeField().then(res => {
					this.none_text_list = res.data.list
					this.get_type()
					if (this.none_text_list.length > 0) {
						this.addVisibleNone = true;
					} else {
						this.addVisible = true;
						this.addType = 'add'
						this.form = {}
					}
				})
			},
			sortProfileInfo() {
				let ids = []
				this.sort_list.forEach(item => {
					ids.push(item.id)
				})
				sortEmployeeProfile({
					data: {
						sort: ids.join(',')
					}
				}).then(res => {
					message.success('操作成功');
					this.get_Employee_ProfileInfo()
					this.sort_list = []
					this.set_sort_visit = false
				})
			},
			get_EmployeeProfileCateInfo(pid) {
				getEmployeeProfileCateInfo({
					data: {
						pid: pid
					}
				}).then(res => {
					this.sort_list = res.data.list
					this.set_sort_visit = true
				})
			},
			delProfileInfo(item) {
				delEmployeeProfile({
					data: {
						id: item.id
					}
				}).then(res => {
					message.success('操作成功');
					this.get_Employee_Attributes_List()
					// this.get_Employee_ProfileInfo()
					// this.get_none_text()
				})
			},
			set_ProfileInfo() {
				this.ProfileInfoForm = {}
				this.set_ProfileInfo_visit = true
			},
			get_EmployeeProfileSelect() {
				getEmployeeProfileSelect().then(res => {
					this.ProfileInfo_Type = res.data.list
				})
			},
			changeTopTab() {

				sessionStorage.setItem("Personneletting_status", this.activeKey);
				if (this.activeKey == 1) {
					this.get_Employee_Attributes_Title()
				}
				if (this.activeKey == 2) {
					this.get_Employee_ProfileInfo()
					this.get_EmployeeProfileSelect()
				}
			},
			get_Employee_ProfileInfo() {
				getEmployeeProfileInfo().then(res => {
					this.Attributes_List = res.data.list
				})
			},
			get_EmployeeAttributeDetail(item) {
				this.field_id = item.id
				getEmployeeAttributeDetail({
					data: {
						field_id: item.id
					}
				}).then(res => {
					this.form = res.data.list
					this.addType = 'edit'
					this.addVisible = true
					this.custom_option = res.custom_option.length ? res.custom_option : [{
						value: ''
					}]
				})
			},
			getProfileInfo(item) {
				this.Profile_id = item.id
				this.set_ProfileInfo_visit = true
				this.ProfileInfo = 'edit'
				this.ProfileInfoForm = JSON.parse(JSON.stringify(item))

			},
			addProfileInfo() {
				let obj = this.ProfileInfoForm
				if (this.ProfileInfo == 'add') {
					saveEmployeeProfile({
						data: obj,
						info: true
					}).then(res => {

						this.get_Employee_ProfileInfo()
						this.set_ProfileInfo_visit = false
						this.ProfileInfoForm = []
						this.ProfileInfo == 'add'

					})
				} else {
					obj.id = this.Profile_id
					editEmployeeProfile({
						data: obj,
						info: true
					}).then(res => {
						this.get_Employee_ProfileInfo()
						this.set_ProfileInfo_visit = false
						this.ProfileInfoForm = []
						this.Profile_id = ''
						this.ProfileInfo == 'add'


					})
				}

			},
			addOk() {
				let obj = {
					...this.form
				}
				if (!obj.name) {
					message.error('请输入字段类型名称');
					return false
				}
				if (obj.type == 'custom' && !this.custom_option[0].value) {
					message.error('请输入字段类型名称');
					return false
				}
				obj.is_search = this.form.is_search ? 1 : -1
				obj.custom_option = this.custom_option
				if (this.addType == 'add') {
					addEmployeeAttribute({
						data: obj,
						info: true
					}).then(res => {
						this.get_Employee_Attributes_List()
						this.addVisible = false
						this.form = {
							pid:''
						}
						this.form.type = this.FieldName_Type[0].value
					})
				} else {
					obj.field_id = this.field_id
					editEmployeeAttribute({
						data: obj,
						info: true
					}).then(res => {
						this.get_Employee_Attributes_List()
						this.addVisible = false
						this.form =  {
							pid:''
						}
						this.field_id = ''
						this.form.type = this.FieldName_Type[0].value
					})
				}

			},
			get_FieldNameType() {
				getFieldNameType().then(res => {
					this.FieldName_Type = res.data.list
					this.form.type = res.data.list[0].value
				})
			},
			get_type() {
				getCanAddFieldType().then(res => {

					this.CanAddField_Type = res.data.list

				})
			},
			get_Employee_Attributes_List(type) {
				if (type) {
					this.activeKeyTab = type
				}
				getEmployeeAttributesList({
					data: {
						type: this.activeKeyTab
					}
				}).then(res => {
					this.Attributes_List = res.data.list
				})
			},
			get_Employee_Attributes_Title() {
				getEmployeeAttributesTitle().then(res => {
					this.tablist = res.data.list
					this.get_Employee_Attributes_List(res.data.list[0].value)
				})
			},
			changStatus(item) {
				changeEmployeeAttributeField({
					data: {
						field_id: item.id
					},
					info: true
				}).then(res => {
					this.get_Employee_Attributes_List()

				})
			},
			changeTitleStatus(item) {
				changeEmployeeAttributeStatus({
					data: {
						pid: item.id
					},
					info: true
				}).then(res => {
					this.get_Employee_Attributes_Title()

				})
			},


			getIndustry() {
				getOrganizationInfo().then(res => {
					res.list.forEach(item => {
						if (item.status == 1) {
							item.check = true
						} else {
							item.check = false
						}
					})
					this.select_list = res.data.list
				})
				getLimitInfo().then(res => {
					res.list.forEach(item => {
						if (item.status == 1) {
							item.check = true
						} else {
							item.check = false
						}
					})
					this.select_list3 = res.data.list
				})
			},
			handle_submit() {

			},
			cancelProfile() {
				this.ProfileInfo = 'add';
				this.ProfileInfoForm = {};
				this.set_ProfileInfo_visit = false
			}

		},

	}
</script>

<style lang="less" scoped>
	@import url("../../assets/less/app.less");
	@import 'info.less';

	.FieldSettings {
		padding: 16px 24px;
		margin: 16px 0px 0px 16px;
		background: #fff;
		margin-right: 16px;
	}

	.table_list_body {
		margin: 0;
		padding: 0;
	}

	.check {
		color: @cq_color;
		cursor: pointer;
	}

	.del {
		color: @cq_error;
	}

	.count_info {
		width: 100%;
		display: flex;
		padding-top: 12px;
	}

	/*被拖拽对象的样式*/
	.item {
		padding: 6px;
		background-color: #fdfdfd;
		border: solid 1px #eee;
		margin-bottom: 10px;
		cursor: move;
		width: 100%;
		display: flex;
		align-items: center;

		span:nth-child(1) {
			flex: 1;
		}
	}

	/*选中样式*/
	.chosen {
		border: solid 2px #3089dc !important;
	}

	.FieldSettingsBox {
		border: 1px solid @cq_line_e;
		margin-top: 12px;
		margin-bottom: 30px;

	}

	.FieldSettingsBoxTitle {
		display: flex;
		width: 100%;
		padding: 8px 12px;
		border-bottom: 1px solid @cq_line_e;

		span:nth-child(2) {
			flex: 1;
			margin-left: 8px;
		}

		span:nth-child(3) {
			flex: 2;


		}

		span:nth-child(4) {
			color: #fff;

		}
	}

	.FieldSettingsBoxHeader {
		background: #f5f8fa;
	}

	.FieldSettingsBoxTitle:hover {
		background: #f5f8fa;

		span:nth-child(4) {
			color: #666;
		}

	}

	.chooseItem {
		border: 1px solid @cq_color;
		margin-right: 12px;
		margin-bottom: 12px;
		text-align: center;
		border-radius: 4px;
		position: relative;
		padding: 5px 15px 5px 10px;
		height: 32px
	}

	.chooseItemactieve {
		position: absolute;
		right: -0.9px;
		height: 22px;
		width: 22px;
		bottom: -0.5px;

	}

	.top_title_info {
		display: flex;
		width: 100%;

		align-items: center;

		span:nth-child(2) {
			flex: 1;
		}

		span:nth-child(4) {
			color: @cq_color;
			margin-left: 8px;
			background: none;
		}
	}

	.tab_info {

		display: flex;
		align-items: center;
		width: 100%;
	}

	.gutter-box {
		border: 1px solid @cq_line_e;
		text-align: center;
		line-height: 32px;
		border-radius: 4px;
		margin-bottom: 6px;
	}

	.activeBox {
		color: @cq_color;
		border-color: @cq_color;
	}

	/deep/.ant-row {
		margin-bottom: 12px;
	}

	.stopBox {
		text-align: center;
		height: 200px;
		padding-top: 20px;

		.top_info_tips {
			color: @cq_warning_ff;

			span:nth-child(1) {
				margin-right: 3px;

			}
		}
	}

	.sLimg {
		display: block;
		height: 200px;
		width: 560px;
	}

	.pointerCancel {
		padding: 0 12px;
	}

	.close_open {
		margin-left: 10px;
	}

	/deep/.page_content {
		background: #fff;
		padding: 0px;
		margin: 0px;
	}

	.tips_top_box {
		.close_open {
			color: #fff;
		}
	}

	.tips_top_box:hover {
		.close_open {
			color: @cq_color;
		}
	}
</style>